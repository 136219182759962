import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import covid19 from '@/assets/img/emergency-relief/covid19@2x.png';
import CardImg1 from '@/assets/img/emergency-relief/covid19_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/emergency-relief/covid19_card_img2@2x.jpg';
import WedoIco1 from '@/assets/img/emergency-relief/covid19_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/emergency-relief/covid19_obj1_2@2x.png';
import WedoIco3 from '@/assets/img/emergency-relief/covid19_obj1_3@2x.png';
import WedoIco5 from '@/assets/img/emergency-relief/covid19_obj1_5@2x.png';
import WedoIco6 from '@/assets/img/emergency-relief/covid19_obj1_6@2x.png';
import WedoIco7 from '@/assets/img/emergency-relief/covid19_obj1_7@2x.png';
import WedoIco8 from '@/assets/img/emergency-relief/covid19_obj1_8@2x.png';
import WedoIco9 from '@/assets/img/emergency-relief/covid19_obj1_9@2x.png';
import covid19M from '@/assets/img/emergency-relief/covid19M@2x.png';
import WedoIco4 from '@/assets/img/emergency-relief/humanitarian_obj1_2@2x.png';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import {
  EmergencyHeader,
  SectionWedo,
  WhyFlex,
} from '@/components/EmergencyRelief';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `지속적인 보건, 교육, 사회 서비스 제공`,
    dec: `유니세프는 코로나19 상황에서도 어린이가 건강하고 행복하게 자랄 수 있도록 다방면의 분야에서 구호활동을 합니다. 유니세프는 코로나19 백신 접종에 밀려 어린이가 필요한 다른 백신을 맞지 못하는 일이 없도록 모니터링하고, 지속적인 예방 접종을 실시합니다. 코로나19로 인해 닫은 보건 시설을 대신해 임시 의료시설, 의료진 등을 지원하며 코로나19 예방과 치료를 위해 가운, 마스크, 고글, 장갑 등 개인보호장비도 지원합니다. 학교가 문을 닫아 배움을 놓치는 어린이가 없도록, 라디오, 전자기기 지원 등을 통해 원격교육을 실시합니다. 더불어, 코로나19로 생활이 어려워진 어린이와 가족들에게 직업교육 및 현금지원을 진행합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `코로나19 및 예방 관련 홍보`,
    dec: `유니세프는 정부 및 파트너와 협력해 잘못된 정보를 추적 및 대응하고, 어린이와 그 가족들이 코로나19로부터 스스로를 보호하고 도움을 구하는 방법을 교육합니다. 또한 소셜 미디어에서 활동하는 청소년, 인플루언서들과 협력해 인식 개선과 참여를 유도합니다. 뿐만 아니라 지역사회 단체, 여성 및 청소년 단체, 보건 종사자, 장애인 단체, 자원봉사자 등 관련자 대상 인식 개선 교육을 하고 홍보 활동을 펼칩니다.`,
    img: CardImg2,
  },
];

const wedo = [
  {
    id: 1,
    img: WedoIco1,
    desc: [
      {
        dec: `의료용품 2억 9,240만 <br/>달러 상당 전달`,
      },
    ],
  },
  {
    id: 2,
    img: WedoIco8,
    desc: [
      {
        dec: `의약품 590만 달러 <br/>상당 전달`,
      },
    ],
  },
  {
    id: 3,
    img: WedoIco3,
    desc: [
      {
        dec: `식수와 위생 용품 <br/>900만 달러 상당 전달`,
      },
    ],
  },
  {
    id: 4,
    img: WedoIco6,
    desc: [
      {
        dec: `집중산소치료기 <br/>20,716개 전달`,
      },
    ],
  },
  {
    id: 5,
    img: WedoIco4,
    desc: [
      {
        dec: `51개국에 진단키트 <br/>850만개 전달`,
      },
    ],
  },
  {
    id: 6,
    img: WedoIco7,
    desc: [
      {
        dec: `개인보호장비 1억 6,000만 달러 상당 전달`,
      },
    ],
  },
  {
    id: 7,
    img: WedoIco9,
    desc: [
      {
        dec: `84개국에 주사기 <br/>10억 5,000만 개 전달 `,
      },
    ],
  },
];

const SectionWedoRe = styled(SectionWedo)`
  .process-ol {
    & > li:nth-child(1),
    & > li:nth-child(2),
    & > li:nth-child(3) {
      width: 33.33%;
    }

    ${breakpoint(`tablet`)} {
      & > li:nth-child(1) {
        width: 100% !important;
      }
    }
  }
`;
const Covid19Vaccine: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST11'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A015'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="코로나19와 백신"
      description="for every child, a better future"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 코로나19 상황에서도 건강하고{` `}
                  <br className="small-hide" />
                  행복하게 자랄 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                2023년 5월, WHO는 코로나19 종식 선언을 했습니다. 2022년 9월을
                기준으로 전 세계 모든 국가가 폐쇄했던 학교 문을 다시 열었습니다.
                하지만 많은 어린이와 가족이 코로나19로 인한 후유증을 겪고
                있습니다. 코로나19때문에 저·중소득 국가에서 학습 빈곤율은
                70%까지 높아졌습니다. 감소 추세였던 조혼과 할례 풍습이 다시
                증가했으며, 어린이를 포함한 가구의 3분의 2가 수익이 줄거나
                없어졌습니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="v8MbP5ko5gk" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <EmergencyHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                코백스를 통해 <br />
                백신을 전달하는 유니세프
              </Tit>
            </h2>
            <p className="header-dec">
              유니세프는 코로나 19 백신 공동 구매 및 배분을 위한 기관 코백스
              퍼실리티(COVAX FACILITY)를 통해 백신 전달 및 보급을 진행합니다.
              유니세프는 2021년부터 146개국에 20억 회분의 코로나19 백신을
              전달했습니다. 이를 위해 전 세계 항공 화물 용량과 운송 경로를
              조사했으며, 백신 보급 우선순위를 정하기 위해 항공사 및 물류 산업과
              협력합니다.
            </p>
          </EmergencyHeader>

          <WhyFlex>
            <div className="col col-left">
              <h3>
                <Tit size="s1-5" color="sky" weight="normal">
                  “왜 유니세프가 하나요?”
                </Tit>
              </h3>
              <p className="dec">
                유니세프는 매년 100개국 등에서 전염병 감염 예방과 치료를 위해 약
                20억 회분의 백신을 전달하고 있는데 이는 세계에서 가장 큰
                규모입니다. 유니세프는 지난 20년 동안 어린이 약 7억 6,000만
                명에게 예방접종을 해왔고, 약 1,300만 명 이상의 생명을
                구했습니다. 아울러 유니세프의 숙달된 의료 전문가와 탄탄한
                콜드체인시스템(저온유통체계)은 코로나19 백신의 안전한 이동과
                보관을 보장합니다.
              </p>
            </div>
            <div className="col col-right">
              <Image pcSrc={covid19} mobileSrc={covid19M} />
            </div>
          </WhyFlex>

          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionWedoRe className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                코로나 19에 대응하기 위해 <br />전 세계 파트너와 함께 유니세프가
                한 일
              </Tit>
            </h2>
            <p className="header-dec">
              2022년 유니세프는 코로나19 상황에 처한 전 세계 어린이에게 보건,
              영양, 교육, 보호, 식수와 위생 서비스를 제공했습니다.
            </p>
          </SectionHeader>

          <ProcessList itemData={wedo} />
        </Container>
      </SectionWedoRe>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A015" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Covid19Vaccine;
